// === Width and height ===
.w-100 { width: 100% !important }
.w-75 { width: 75% !important }
.w-50 { width: 50% !important }
.w-25 { width: 25% !important }
.w-fit { width: fit-content !important }

.h-100 { height: 100% !important }
.h-75 { height: 75% !important }
.h-50 { height: 50% !important }
.h-25 { height: 25% !important }
.h-fit { height: fit-content !important }

.font-bold { font-family: var(--main-font-bold) }
.font-semi-bold { font-family: var(--main-font-semi-bold) }
.font-medium { font-family: var(--main-font-medium) }

.primary-color { color: var(--primary-color) }
.secondary-color { color: var(--secondary-color) }

.text-underline { text-decoration: underline }
.text-bold { font-weight: bold }
.text-unbold { font-weight: normal }
.text-left { text-align: left }
.text-right { text-align: right }
.text-center { text-align: center }
.text-uppercase { text-transform: uppercase }

.no-select-text { // to disable selecting the text
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// === Cursor ===
.pointer { cursor: pointer }
.not-allowed { cursor: not-allowed}

// === Alignments ===
.to-left { float: left }
.to-right { float: right }
.no-margin { margin: 0px !important }
.no-padding { padding: 0px !important }
.m-auto { margin: auto }
.p-auto { padding: auto }

// === ETC ===
.opacity-50 { opacity: 0.5; }
.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}
.hidden {
  visibility: hidden;
}

.shadow {
  filter: drop-shadow(0px 0px 8px var(--shadow-color));
}