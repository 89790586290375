@import "antd/dist/reset.css";
@import "./variables.scss";
@import "./general.scss";
@import "./button.scss";
@import "./font-face.scss";
@import "./popup.scss";
@import "./animation.scss";

body {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: var(--main-font-base);
  font-size: var(--font-size-m);
  color: var(--font-size-m);
}

body::-webkit-scrollbar { display: none } // hide scrollbar

label { 
  font-family: var(--main-font-semi-bold);
  font-weight: 700;
}

html {
  height: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

#app { 
  background-color: var(--main-bg-color);
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 100vh;
}

*,
*:before,
*:after { box-sizing: inherit }

* {
  margin: 0;
  padding: 0;
}

* {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

* {
  /* Customized all scrollbar */
    /* width */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    // LATER: dont stick to right side
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(lightgrey, 0.2);
    border-radius: 20px;
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--primary-color-40-opacity);
    border-radius: 20px;
  }
}

a:focus,
a:active { outline: none }

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

div:focus { outline: none }

#app-main {
  min-height: 100vh;
  height: calc(100% - 1rem);
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  // required red asterisk
  font-size: 25px;
  margin-inline-end: 2px;
}

.ant-btn {
  font-family: var(--main-font-bold);
}

// override antd css
p {
  margin-bottom: 0;
}

// ReactSVG div wrapper
.svg-wrapper {
  display: unset;
}

// select dropdown css
.ant-select-dropdown {
  padding: 0;
  .ant-select-item-option-content {
    font: normal normal normal var(--font-size-m) var(--main-font-semi-bold);
    color: #8F99A8;
    padding: 5px 0;

    .option-title,
    .option-content {
      color: #253858;
    }

    .option-content {
      opacity: 0.3;
    }
  }
  .ant-select-item {
    padding: 5px 10px;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0;
    }
  }

  .ant-select-item-option-disabled {
    .ant-select-item-option-content {
      .ant-badge-status-text {
        color: #8F99A8 !important;
      }

      .ant-badge-status-dot {
        background-color: #8F99A8 !important;
      }
    }
  }
}

// Responsive //--mobile-max-width
@media (max-width: 992px) {
  .ant-select-dropdown {
    .ant-select-item-option-content {
      font: var(--font-size-s) var(--main-font-base);
      padding: 0;
    }
  }
}

.ant-select-selection-item {
  font-family: var(--main-font-semi-bold) !important;
}

// checkbox css
.ant-checkbox-wrapper {
  >span {
    color: #8F99A8;
    font-weight: normal;
  }
  &.ant-checkbox-wrapper-checked {
    >span {
      color: #1A2B42;
    }
  }
}
.ant-checkbox {
  transform: none;
  .ant-checkbox-inner {
    width: 1.35rem;
    height: 1.35rem;
  }
  &.ant-checkbox {
    &-checked {
      .ant-checkbox-inner {
        background: var(--primary-color) !important;
        border-color: transparent;
        &:after {
          height: 0.75rem;
          width: 0.5rem;
          transform: rotate(45deg) scale(1) translate(-60%,-55%);
        }
      }
      &:after {
        display: none !important;
      }
    }
    &-indeterminate {
      .ant-checkbox-inner {
        &:after {
          background: var(--primary-color);
        }
      }
    }
  }
}

// card css
.ant-card {
  border-radius: 10px;
  &.ant-card-bordered {
    border: 1px solid #EBF1FD;
  }
  &:not(.ant-card-bordered) {
    box-shadow: none;
  }
}

// tag css
.ant-tag {
  padding-inline: 5px;
}

// date-picker (range-picker) css
.ant-picker-range {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  .ant-picker-input {
    input {
      text-align: center;
    }
  }

  &.ant-picker-focused {
    box-shadow: none !important;
  }
}

.ant-picker-header-view {
  font: normal normal normal var(--font-size-s) var(--main-font-semi-bold) !important;
}

// input css
input {
  font-family: var(--main-font-semi-bold) !important;
}

.ant-input {
  &:hover,
  &:focus {
    box-shadow: unset !important;
    border-color: #BBEFE5 !important;
  }
}

@mixin placeholder-style {
  color: #BEBEBE !important;
  opacity: 1;
  font-size: var(--font-size-m);
  font-weight: 500 !important;
}

/* do not group these rules */
input::-webkit-input-placeholder {
  @include placeholder-style;
}
input:-moz-placeholder {
  /* FF 4-18 */
  @include placeholder-style;
}
input::-moz-placeholder {
  /* FF 19+ */
  @include placeholder-style;
}
input:-ms-input-placeholder {
  /* IE 10+ */
  @include placeholder-style;
}
input::-ms-input-placeholder {
  /* Microsoft Edge */
  @include placeholder-style;
}
input::placeholder {
  /* modern browser */
  @include placeholder-style;
}

// textarea css
textarea {
  font-family: var(--main-font-semi-bold) !important;
}

// status badge css 
.status-ACTIVE {
  .ant-badge-status-default {
    background-color: var(--active)!important;
  }
}
.status-INACTIVE {
  .ant-badge-status-default {
    background-color: var(--inactive)!important;
  }
}
.status-PENDING {
  .ant-badge-status-default {
    background-color: var(--pending)!important;
  }
}
.status-APPROVED,
.status-ACCEPTED {
  .ant-badge-status-default {
    background-color: var(--approved)!important;
  }
}
.status-REJECTED {
  .ant-badge-status-default {
    background-color: var(--rejected)!important;
  }
}
.status-RETURNED {
  .ant-badge-status-default {
    background-color: var(--returned)!important;
  }
}
.status-ARCHIVED {
  .ant-badge-status-default {
    background-color: var(--archived)!important;
  }
}

// radio css
.ant-radio-group.ant-radio-group-solid {
  display: flex;
  gap: 0.5rem;
  .ant-radio-button-wrapper {
    font: normal normal normal var(--font-size-m) var(--main-font-semi-bold);
    flex-basis: 100%;
    border-radius: 5px;
    border: none;
    background: #F8F8F8;
    color: var(--default-font-color);
    min-height: 3rem;

    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:first-child)::before {
      display: none;
    }

    >span {
      color: inherit;
      opacity: 0.5;
      font-size: var(--font-size-m);
    }

    &-checked {
      background: var(--icon-active);
      color: white;
      >span {
        opacity: 1;
      }
    }
  }
}

.ant-radio {
  .ant-radio-inner {
    width: 26px;  
    height: 26px;

    &::after {
      width: 26px;
      height: 26px;
      inset-block-start: 28%;
      inset-inline-start: 28%;
    }
  }

  &.ant-radio-checked {
    .ant-radio-inner {
      border-color: #d9d9d9;
      background-color: transparent;

      &::after {
        transform: scale(0.6);
        background-color: #009FB2;
      }
    }
  }

  &-wrapper {
    font: normal normal normal var(--font-size-s) var(--main-font-base);
  }
}

// switch css 
.ant-switch {
  height: 2rem;
  width: 3.4rem;

  .ant-switch-handle {
    width: 1.7rem;
    height: 1.7rem;
    &::before {
      border-radius: 50%;
    }
  }

  .ant-switch-inner {
    background: #e9e9eb;
  }

  &.ant-switch-checked {
    .ant-switch-handle {
      inset-inline-start: calc(100% - 1.85rem);
    }
    .ant-switch-inner {
      background: var(--active);
    }
  }
  
}

// modal css
.ant-modal-wrap {
  overflow-x: hidden !important;
}

.ant-modal-confirm-title {
  font: normal normal normal var(--font-size-m) var(--main-font-semi-bold) !important;
}

// divider css
.expanded-divider {
  // please add overflow-x: hidden; to the parent if needed
  width: 200%;
  position: relative;
  left: -50%;
}

// hide ant design upload box
.image-type-box.hide-upload-container,
.hide-upload-container .ant-upload-select {
  display: none !important;
}

// assign-user-modal css
.modal-table {
  .ant-table {
    margin-top: 0.5rem;
  }

  .ant-table-body {
    padding-right: 1rem;
  }

  .ant-table-thead {
    tr {
      th,
      td {
        background-color: unset;
        border-bottom: unset;
      }

      th:before {
        background-color: unset !important;
      }
    } 
  }

  .ant-table-body {
    table {
      border-spacing: 0 8px;
    }
  }

  .ant-table-tbody {
    tr.ant-table-row:hover {
      td {
        background: none !important;
      }
    }
  }

  .ant-table-column-sort {
    background-color: unset !important;
  }

  .ant-table-column-has-sorters {
    ::before {
      background-color: unset;
    }
  }
}