/* Animation */
/* ----------------------------------------------
 * Generated by Animista on 2023-10-12 8:45:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * NOTE: try to only use transform, opacity and filter for better performance
 * https://developer.mozilla.org/en-US/docs/Learn/Performance/CSS#choosing_properties_to_animate
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      opacity: 0;
    }
    80% {
      opacity: 0.2;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      opacity: 0;
    }
    80% {
      opacity: 0.2;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }