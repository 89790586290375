.ant-btn.custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--main-font-bold);
  border-radius: 10px;
  border: 2px solid;
  box-shadow: none;
  padding: 1.25rem 1.4rem;
  gap: 0.8rem;

  .wave-motion-appear {
    // disable ant design button wave effect
    display: none;
  }

  &.primary {
    color: var(--primary-text-color);
    background-color: var(--primary-button-color);
    border-color: var(--primary-border-color);
    svg {
      color: var(--primary-text-color) !important;
    }

    &:hover {
      color: var(--primary-text-color);
      background-color: var(--primary-button-color);
      border-color: var(--primary-border-color);
    }
  }

  &.secondary {
    color: var(--secondary-text-color);
    background: var(--secondary-button-color);
    border-color: var(--secondary-border-color);
    svg {
      color: var(--secondary-text-color) !important;
    }

    &:hover {
      color: var(--secondary-text-color);
      background: var(--secondary-button-color);
      border-color: var(--secondary-border-color);
    }
  }

  &.filter {
    color: var(--button-grey-text-color);
    background: var(--secondary-button-color);
    border-color: var(--secondary-border-color);
    font-family: var(--main-font-semi-bold);
    padding: 22px 10px;
    svg {
      color: var(--button-grey-text-color) !important;
    }

    &:hover {
      color: var(--button-grey-text-color);
      background: var(--secondary-button-color);
      border-color: var(--secondary-border-color);
      svg {
        color: var(--button-grey-text-color);
      }
    }
    &:focus,
    &.active {
      color: var(--primary-text-color);
      background: var(--filter-button-focus-color);
      border-color: var(--filter-button-focus-color);
      svg {
        color: var(--primary-text-color) !important;
      }
    }
  }

  &.text-pattern {
    color: var(--button-grey-text-color);
    background: transparent;
    border-color: transparent;
    svg {
      color: var(--button-grey-text-color) !important;
    }
    &.black-text {
      color:  var(--secondary-text-color) !important;
    }

    &:hover {
      color: var(--button-grey-text-color);
      background: transparent;
      border-color: transparent;
    }
  }

  &.white {
    color: var(--filter-button-focus-color);
    background-color: var(--primary-text-color);
    border-color: var(--white-border-color);
    svg {
      color: var(--filter-button-focus-color) !important;
    }

    &:hover {
      border-color: var(--white-border-color);
    }
  }

  &.green {
    color: var(--filter-button-focus-color);
    background-color: var(--green-button-color);
    border-color: var(--green-button-color);
    svg {
      color: var(--filter-button-focus-color) !important;
    }
    &.disabled-hover:hover {
      color: var(--filter-button-focus-color);
      background-color: var(--green-button-color);
      border-color: var(--green-button-color);
      svg {
        color: var(--filter-button-focus-color) !important;
      }
    }

    &:hover {
      color: var(--primary-text-color);
      background-color: var(--filter-button-focus-color);
      border-color: var(--filter-button-focus-color);
      svg {
        color: var(--primary-text-color) !important;
      }
    }
  }

  &.grey {
    color: var(--filter-button-focus-color);
    background-color: var(--grey-button-color);
    border-color: var(--grey-button-color);
    svg {
      color: var(--filter-button-focus-color) !important;
    }
    &.disabled-hover:hover {
      color: var(--filter-button-focus-color);
      background-color: var(--grey-button-color);
      border-color: var(--grey-button-color);
      svg {
        color: var(--filter-button-focus-color) !important;
      }
    }

    &:hover {
      color: var(--filter-button-focus-color);
      background-color: var(--button-green-hover-color);
      border-color: var(--button-green-hover-color);
      svg {
        color: var(--filter-button-focus-color) !important;
      }
    }
  }

  &:disabled,
  &[disabled] {
    opacity: 0.5;
  }

  &.full-width {
    width: 100%;
  }
}

.circular-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--main-font-bold);
  border-radius: 2rem;
  border: 2px solid;
  box-shadow: none;
  padding: 1rem 1.05rem;
  gap: 0.8rem;

  .wave-motion-appear {
    // disable ant design button wave effect
    display: none;
  }

  &.green {
    color: var(--filter-button-focus-color);
    background-color: var(--green-button-color);
    border-color: var(--green-button-color);
    svg {
      color: var(--filter-button-focus-color) !important;
    }

    &:hover {
      color: var(--filter-button-focus-color);
      background-color: var(--button-green-hover-color);
      border-color: var(--button-green-hover-color) !important;
      svg {
        color: var(--filter-button-focus-color) !important;
      }
    }
  }

  &.grey {
    color: var(--secondary-text-color);
    background-color: var(--grey-button-color);
    border-color: var(--grey-button-color);
    svg {
      color: var(--secondary-text-color) !important;
    }

    &:hover {
      color: var(--secondary-text-color);
      background-color: var(--button-grey-hover-color);
      border-color: var(--button-grey-hover-color) !important;
      svg {
        color: var(--secondary-text-color) !important;
      }
    }
  }

  &:disabled,
  &[disabled] {
    opacity: 0.5;
  }
}

.ant-radio-button-wrapper {
  .wave-motion-appear {
    // disable ant design button wave effect
    display: none;
  }
}



