.department-select-dropdown {
  .ant-select-item {
    padding: 0px 5px !important;

    &-option {
      background-color: transparent !important;
      padding: 0;
      margin: 0 0.25rem !important;
      border-bottom: none !important;

      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -6px;
        left: 0;

        width: 100%;
        height: 1px;
        border-bottom: 1px solid rgba(grey, 0.2);
      }

      &-content {
        padding: 0.5rem 1rem !important;
        margin-top: calc(5px + 5px);
        border-radius: 5px;
      }

      &:hover,
      &-selected {
        .ant-select-item-option-content {
          background-color: var(--primary-color-20-opacity) !important;
          color: var(--default-font-color);
        }
      }
    }

    &-option:first-child {
      .ant-select-item-option-content {
        margin-top: 5px;
      }
    }
  }
}

.dashboard-overview-avatar-info-overlay {
  .ant-popover-arrow {
    display: none;
  }
  
  .ant-popover-inner {
    padding: 1rem;
    border: 1px solid var(--default-font-color);
    border-radius: 5px;
    max-height: 50vh;
    overflow-y: auto;
  }

  &.more {
    .ant-popover-inner {
      padding: 0 1rem;
    }
  }

  .popover-first-row,
  .popover-second-row,
  .popover-third-row {
    font: normal normal normal var(--font-size-s) var(--main-font-semi-bold);
    margin: 0;
  }

  .popover-first-row {
    font-size: var(--font-size-m);
    color: var(--default-font-color);
  }

  .popover-third-row {
    color: #8D8D8D;

    .time {
      margin-left: 0.5rem;
      font-family: var(--main-font-base);
      font-size: 0.8rem;
    }
  }

  .more-avatar-row {
    gap: 0.75rem;
    padding: 0.5rem 0;

    .more-avatar-desc {
      flex-grow: 1;
    }
    
    .ant-avatar {
      border: 1px solid #EBF1FD;
      width: 2.8rem;
      height: 2.8rem;
      line-height: 2.8rem;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #EBF1FD;
    }
  }

  .half-tag {
    margin-left: 0.5rem;
  }
}

.date-range-overlay,
.year-selection-overlay {
  visibility: hidden;
}


.claim-summary-overlay {
  max-width: 350px;
}

.assign-confirmation-box {
  .box {
    padding: 1rem;
    border-radius: 5px;
    width: 100%;

    &.assign {
      background: #E7F8EB;
      .tag {
        background: #85DB99;
      }
    }
    &.unassign {
      background: #FFECEA;
      .tag {
        background: #F46767;
      }
    }
  }
  .tag {
    padding: 0.25rem 0.75rem;
    color: white;
    border-radius: 2rem;
  }
  .avatar-row {
    margin-top: 1rem;
  }

  .ant-modal-confirm-btns {
    display: flex;
    gap: 1rem;
    .ant-btn {
      width: 100%;
      height: 3rem;
    }
  }

  .ant-modal-confirm-btns .ant-btn+.ant-btn {
    margin-inline-start: 0;
  }

  .ant-modal-confirm-body {
    justify-content: center;
  }
}

.bo-header-select-dropdown {
  .ant-select-item {
    padding: 0.3rem !important;

    &-option {
      background-color: transparent !important;
      border-radius: 4px;
      
      &-content {
        padding: 0.3rem 0.75rem !important;
        margin: 0;
        border-radius: 4px;
        display: flex;
        justify-content: center;
      }

      &:hover,
      &-selected {
        .ant-select-item-option-content {
          background-color: rgba(0, 0, 0, 0.04) !important;
          color: var(--default-font-color);
        }
      }
    }
  }
}

.company-asset-status-dropdown {
  .ant-select-item-option-content {
    .ant-badge-status-text {
      font-family: var(--main-font-semi-bold);
      font-size: 16px !important;
      color: #8F99A8 !important;
      padding: 5px 0;
    }
  }
}

.avatar-info-overlay {
  .ant-tooltip-inner {
    padding: 0.25rem 0.75rem;
    border: 1px solid var(--default-font-color);
    border-radius: 5px;
    max-height: 50vh;
    overflow-y: auto;
    font-size: var(--font-size-s);
    font-weight: bold;
    color: var(--default-font-color);
    margin: 0;
    background-color: var(--primary-text-color);
  }
}

.ant-popconfirm {
  .ant-popconfirm-buttons {
    display: flex;
    gap: 1rem;

    .ant-btn {
      width: 100%;
      margin-inline-start: 0;
    }
  }
}

.ant-modal-root {
  .ant-modal-confirm-btns {
    display: flex;
    gap: 1rem;

    .ant-btn, .ant-btn+.ant-btn {
      width: 100%;
      margin-inline-start: 0;
    }
  }
}

.content-flex {
  .ant-spin-nested-loading {
    flex: 1 !important;
  }
}

.disabledTimePickerConfirm {
  .ant-btn {
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.04);
    box-shadow: none;
    pointer-events: none;
  }

  .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.25);
  }
}