:root {
  /* special color */
  --translucent-black: rgba(56, 56, 56, 0.4);

  /* general */
  --main-font-base: PoppinsRegular;
  --main-font-bold: PoppinsBold;
  --main-font-semi-bold: PoppinsSemiBold;

  --font-size-2xs: 0.5rem; /* 8px */
  --font-size-xs: 0.65rem; /* 10.4px */
  --font-size-s: 0.85rem; /* 13.6px */
  --font-size-m: 1rem; /* 16px */
  --font-size-l: 1.25rem; /* 20px */
  --font-size-xl: 1.5rem; /* 24px */
  --font-size-2xl: 2rem; /* 32px */
  --font-size-3xl: 2.5rem; /* 40px */

  --spacing-left-right: 1rem;  
  --view-max-width: 1440px;

  --bo-header-height: 80px;
  --bo-spacing-left: 120px;
  --bo-spacing-right: 30px;
  --bo-vertical-spacing: 1.5rem;

  --sidebar-left: 1.5rem;
  --sidebar-width-collapsed: 5rem;

  /* Icon */
  --icon-default: #727d91;
  --icon-active: #042422;

  /* Border */
  --border-sidebar: #f1f6fa;
  --border-common-color: #f1f6fa;

  /* Status */
  --active: #00BE48;
  --inactive: #f6423a;
  --archived: #253858;
  --pending: #F5A174;
  --approved: #85DB99;
  --rejected: #F46767;
  --returned: #85CADB;

  --performance-good: #00B72B;
  --performance-moderate: #FF9555;
  --performance-under: #FF2C2F;
  --overtime-color: #0045C1;

  --claim-approved: #00B72B;
  --claim-pending: #FF9555;
  --claim-rejected: #FF2C2F;
  
  --claim-success: #A5D7C0;
  --claim-warning: #F2A15A;
  --claim-danger: #FC3318;

  --asset-pending: #FFEDE0;

  --danger: red;

  --primary-color: #7CE2D8;
  --secondary-color: #F5FDFC;
  --main-bg-color: #F5FDFC;
  --default-font-color: #253858;
  --shadow-color: #2538581A;
  --title-font-color: #8790A0;

  --primary-color-10-opacity: #7CE2D81A;
  --primary-color-20-opacity: #7CE2D833;
  --primary-color-40-opacity: #7CE2D866;
  --primary-color-80-opacity: #7CE2D8CC;

  --primary-button-color: #042422;
  --primary-text-color: #FFFFFF;
  --primary-border-color: #042422;
  --secondary-button-color: #F5FDFC;
  --secondary-text-color: #042422;
  --secondary-border-color: #E1E5EE;
  --button-grey-text-color: #9099A9;
  --filter-button-focus-color: #253858;
  --white-border-color: #EBF1FD;
  --green-button-color: #DFF7F4;
  --button-green-hover-color: #7CE2D8;
  --grey-button-color: #E5E7EB;
  --button-grey-hover-color: #C4C4C4;

  --form-input-active-color: #BBEFE5;

  --leave-color: #F6F0FE;
  --leave-text-color: #AF94E0;
  --leave-badge-color: #AF94E0;
  --task-color: #FFF6EA;
  --task-text-color: #EACE9F;
  --unpaid-leave-color: #FDEBE8;
  --unpaid-leave-text-color: #F2ADA4;

  --leave-bg: #CAB1EF;
  --leave-bg-20-opacity: #cbb3ef33;
  --task-bg: #FADAAD;
  --task-bg-80-opacity: #fff6ebcc;
  --event-bg: #A1D6ED;
  --event-bg-30-opacity: #a1d6ed4d;
  --event-badge-color: #A1D6ED;

  --holiday-bg-20-opacity: #89fae433;
  --holiday-icon-bg: #9EE6D9;
  --holiday-badge-color: #88fae3;

  --birthday-color: #F8ACF0;
  --birthday-30-color: #fa89e930;
  --public-holiday-color: #009FB2;

  --replacement-bg-80-opacity: #FDEFED;
  --replacement-bg: #EFB3B3;
  --replacement-badge-color: #FDEBE8;

  /* Mobile View */
  --mobile-max-width: 992px; // refer to Bootstrap's responsive design
  --mobile-bo-spacing-left-right: 1rem;
}